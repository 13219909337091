import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderData:{}
  },
  getters: {
  },
  mutations: {
    SET_ORDER_HISTORY(state,data){
      console.log(state,"state.orderData")
      state.orderData = data;
    }
  },
  actions: {
    async getorderList({commit}){
      let result =  await axios.get("/api/kdsResults/orderList").then(res=>{
          return res.data
      })
      if(result){  
        commit('SET_ORDER_HISTORY',result)
      }
    }
  },
  modules: {
  }
})
