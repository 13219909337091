import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts= echarts;

//axios
import axios from 'axios';
Vue.prototype.$axios = axios;
// import vueAxios from 'vue-axios';
// Vue.use(axios,vueAxios);

//事件总线
Vue.prototype.$eventbus = new Vue;

// iconfonts
import './assets/icon/iconfont/iconfont.css'
//公共样式
import './assets/css/base.css'
import './assets/css/comm.less'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
